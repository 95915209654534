.App {
  display: flex;
  width: 100;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /*cursor: url('./cursors/vertopal.com_cursor.png'), auto;*/
}
.App video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover; /* Ensures the video covers the entire area */
}

@media screen and (max-width: 575px) {
  .App video {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    object-fit: cover; /* Ensures the video covers the entire area */
  }
}