.Card {
    width: 500px;
    height: 350px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.upper-container {
    height: 100px;
}

.image-container {
    width: 100px;
    height: 100px;
    padding: 5px;
    border-radius: 50%;
    transform: translate(195px, 45px);
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    border-radius: 16px;
}

.lower-container {
    margin-top: 60px;
    height: 300px;
    text-align: center;
    font-family: 'Fira Sans';
}

.lower-container h3, .lower-container h4 {
    margin: 0;
    padding: 0;
}

.lower-container h3 {
    color: white;
    font-weight: 600;
    font-size: 2rem;
}

.lower-container h4 {
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
}

button:hover {
    background-color: #2f2d2e;
    color: white;
    transform: scale(1.1);
    border: none;
}

a {
    color: white;
    padding: 5px;
    transition-duration: 0.5s;
    animation: ease-in-out;
}

a:hover {
    color: #aaa;
    transform: scale(2.5);
}

@media screen and (max-width: 575px) {
    .Card{
        width: 80%;
        height: 350px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }
}